<template>
  <div id="card-custom">
    <v-row class="mx-1">
      <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
        <v-select
          class="background-color-white"
          placeholder="Satker"
          :items="units2"
          v-model="unit2"
          :loading="loadingUnit"
          solo
          flat
          dense
          hide-details
          clearable
          item-text="singkatan"
          item-value="unit_kerja_2_id"
          @change="changeUnit(2)"
          @click:clear="changeUnit(2)"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
        <v-select
          class="background-color-white"
          placeholder="Bagian"
          :items="units3"
          v-model="unit3"
          :loading="loadingUnit"
          solo
          flat
          dense
          hide-details
          clearable
          item-text="singkatan"
          item-value="unit_kerja_3_id"
          @change="changeUnit(3)"
          @click:clear="changeUnit(3)"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
        <v-select
          class="background-color-white"
          placeholder="Sub Bagian"
          :items="units4"
          v-model="unit4"
          :loading="loadingUnit"
          solo
          flat
          dense
          hide-details
          clearable
          item-text="singkatan"
          item-value="unit_kerja_4_id"
          @change="changeUnit(4)"
          @click:clear="changeUnit(4)"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              class="background-color-white"
              label="Bulan"
              append-icon="event"
              solo
              flat
              dense
              hide-details
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            locale="id"
            type="month"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mx-1">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12" class="py-0">
        <v-card-actions>
          <v-spacer />
          <v-btn color="#FBB005" class="px-10" @click="getListRecapitulation">
            <span class="subtitle-2 text-capitalize">Cari</span>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Rekapitulasi Presensi</p>
            <v-spacer />
            <p class="mr-3 mt-2 subtitle-2 font-weight-regular headline-color">
              {{ date | dateMonthShortDisplay }} {{ date | dateYear
              }}<v-icon class="ml-2">today</v-icon>
            </p>
          </v-card-actions>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="mx-2 px-5 text-capitalize"
              >Export Excel</v-btn
            >
          </v-card-actions>
          <v-data-table
            id="table-custom"
            multi-sort
            :headers="headers"
            :items="summaries"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.jumlah_hadir`]="{ item }">
              <span>{{ item.jumlah_hadir }} hari</span>
            </template>
            <template v-slot:[`item.jumlah_izin`]="{ item }">
              <span>{{ item.jumlah_izin }} hari</span>
            </template>
            <template v-slot:[`item.jumlah_dinas`]="{ item }">
              <span>{{ item.jumlah_dinas }} hari</span>
            </template>
            <template v-slot:no-data>
              <ContentNotFound message="Data pegawai dan presensi tidak ada" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AttendanceService from "@/services/resources/attendance.service";
import PublicService from "@/services/resources/public.service";
const ContentNotFound = () => import("@/components/Content/NotFound");

export default {
  components: {
    ContentNotFound
  },
  data() {
    return {
      loadingUnit: false,
      unit2: null,
      unit3: null,
      unit4: null,
      units2: [],
      units3: [],
      units4: [],
      headers: [
        { text: "NIP", value: "NIP", sortable: false, align: "center" },
        { text: "NAMA", value: "nama", sortable: false, align: "center" },
        { text: "JABATAN", value: "jabatan", sortable: false, align: "center" },
        {
          text: "JUMLAH HADIR",
          value: "present",
          sortable: false,
          align: "center"
        },
        {
          text: "JUMLAH IZIN",
          value: "off",
          sortable: false,
          align: "center"
        },
        {
          text: "JUMLAH DINAS",
          value: "duty",
          sortable: false,
          align: "center"
        },
        {
          text: "JUMLAH TIDAK HADIR",
          value: "absent",
          sortable: false,
          align: "center"
        }
      ],
      menu: false,
      date: new Date().toISOString().substr(0, 7),
      totalItem: 0,
      pageCount: 0,
      loading: true,
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      summaries: []
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListRecapitulation);
      },
      deep: true
    },
    type(val) {
      if (val == 0) {
        this.search = null;
      } else if (val == 1) {
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      }
    }
  },
  methods: {
    changeUnit(type = 2) {
      if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await PublicService.getUnitKerjaList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units2 = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListRecapitulation() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await AttendanceService.recapitulationList({
          filter: {
            filter_date: this.date,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let summaries = list;
              summaries.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.summaries = summaries;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #f5f6fa;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    color: #a3a6b4;
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
